import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { ArrowUpIcon } from 'src/Components/Icons/ArrowUpIcon';
import { CheckMarkIcon } from 'src/Components/Icons/CheckMarkIcon';
import { CloseIcon } from 'src/Components/Icons/CloseIcon';
import { WorkoutIcon } from 'src/Components/Icons/WorkoutIcon';
import { useSessions } from 'src/Sessions/useSessions';
import { RawData, SubScores, UserRole } from 'src/graphql/types';

import { BestAndWorstSection } from './BestAndWorstSection';
import { DefaultSubScore } from './defaultSubScores';

import { WipBadge } from '../../../../../Components/WipBadge';
import { TrainingsVideo } from '../../../../../graphql/types/rtk-query';
import { MobileVideoSwiper } from '../../../../Events/Components/MobileVideoSwiper';
import { EventDialog } from '../../../../Events/EventDialog';

const Icon = (props: { active?: boolean }) =>
	props.active ? <CheckMarkIcon /> : <CloseIcon color="red" />;

export type SubScoreBodyProps = {
	selected: boolean;
	isLoading: boolean;
	onClose: () => void;
	selectedSubScore?: SubScores;
	defaultSubScore: DefaultSubScore;
	trainingVideos: TrainingsVideo[];
	onEntered: () => void;
};

export const SubScoreBody: React.FC<SubScoreBodyProps> = ({
	isLoading,
	selected,
	selectedSubScore,
	trainingVideos,
	onClose,
	defaultSubScore,
	onEntered,
}) => {
	const { t } = useTranslation(['performance-stats', 'subscores']);
	const { selectedRole, sessions } = useSessions();
	const params = useParams<{ userId: string }>();

	const drawSubScore = (
		subScore: typeof defaultSubScore.subScores[number],
	) => {
		const selectedRawData = selectedSubScore?.rawData?.find(
			(data) => data.name === subScore.name,
		);

		if (
			!selectedRawData?.value &&
			typeof selectedRawData?.value !== 'number' &&
			sessions?.length
		)
			return null;

		const mapRawData = (rawData?: RawData) => {
			if (rawData?.stringValue?.includes('-')) {
				return rawData.stringValue;
			}

			if (rawData?.stringValue === 'True') {
				return <Icon active />;
			}

			if (rawData?.stringValue === 'False') {
				return <Icon />;
			}

			return typeof rawData?.value === 'number'
				? `${rawData?.value}${rawData?.unit || ''}`
				: '-.-';
		};

		return (
			<Grid item xs={subScore.fullWidth ? 12 : 6} key={subScore.name}>
				<Typography variant="h6">
					{mapRawData(selectedRawData)}
				</Typography>
				<Typography
					variant="body2"
					color="textSecondary"
					sx={{ wordBreak: 'break-word' }}
				>
					{t('subscores:' + subScore.name)}
				</Typography>
			</Grid>
		);
	};

	const [showVideos, setShowVideos] = useState(false);
	return (
		<Collapse in={selected} onEntered={onEntered} appear={selected}>
			<Box
				p={4}
				border="1px solid #252525"
				borderTop={0}
				borderBottom={0}
				borderRadius="2px"
				bgcolor="#151515"
			>
				{selectedRole === UserRole.Trainer && !params?.userId ? (
					<>
						<Typography
							variant="button"
							sx={{ textTransform: 'none' }}
						>
							{t('valuesAreAverages')}
						</Typography>
						<Box pt={2} />
					</>
				) : null}
				<Grid container spacing={3}>
					{defaultSubScore.subScores.map(drawSubScore)}
				</Grid>
			</Box>

			{selectedRole === UserRole.Trainer &&
				!params.userId &&
				selectedSubScore?.value && (
					<Box
						display="flex"
						p={4}
						border="1px solid #252525"
						borderRadius="2px"
						bgcolor="#151515"
					>
						<Grid container spacing={3}>
							<Grid item xs>
								<BestAndWorstSection
									color="primary"
									text={t('bestPlayers')}
									subScorePlayers={
										selectedSubScore.bestPlayers
									}
								/>
							</Grid>
						</Grid>
					</Box>
				)}

			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				p={2}
				pt={4}
				border="1px solid #252525"
				borderRadius="2px"
				bgcolor="#151515"
			>
				{(!_.isEmpty(trainingVideos) || isLoading) &&
				selectedSubScore?.value &&
				!params?.userId ? (
					selectedRole === UserRole.Trainer ? (
						<>
							<Typography variant="body2" align="center">
								{t('improveTitleTeam')}
							</Typography>
							<Box pt={3} />
							<Button
								color="inherit"
								variant="outlined"
								onClick={() => setShowVideos(!isLoading)}
								disabled={isLoading}
								startIcon={<WorkoutIcon color="white" />}
							>
								<WipBadge>{t('createWorkoutButton')}</WipBadge>
							</Button>
							<Box pt={3} />
						</>
					) : (
						<>
							<Typography variant="body2" align="center">
								{t('improveTitle')}
							</Typography>
							<Box pt={3} />
							<Button
								color="inherit"
								variant="outlined"
								onClick={() => setShowVideos(!isLoading)}
								disabled={isLoading}
								startIcon={<WorkoutIcon color="white" />}
								endIcon={
									isLoading ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : null
								}
							>
								{t('workoutButton')}
							</Button>
							<Box pt={3} />
						</>
					)
				) : null}

				<EventDialog
					isOpen={showVideos}
					transition={'Grow'}
					onClose={() => setShowVideos(false)}
				>
					<MobileVideoSwiper videos={trainingVideos} />
				</EventDialog>

				<Typography
					variant="caption"
					color="textSecondary"
					display="block"
					width={1}
				>
					{t('info.' + defaultSubScore?.name)}
					<Box mt={1} sx={{ float: 'right' }}>
						<IconButton size="small" onClick={onClose}>
							<ArrowUpIcon color="white" />
						</IconButton>
					</Box>
				</Typography>
			</Box>
		</Collapse>
	);
};
